<template>
  <div></div>
</template>

<script>
export default {
  name: 'Index',
  mounted() {
    this.$nuxt.context.error({ statusCode: 404, message: 'profil-error' });
  }
};
</script>
